import React from 'react'

export default function Slider(props){
    const {sliderData = []} = props
    return (
        <div className="container-fluid page-scroll">
            {/* <!-- ==== Slider ==== --> */}
            <div id="slider" style={{width:"1200px", height:"900px", margin:"0 auto", marginBottom: "0px"}}>
            {sliderData && sliderData.length? 
            sliderData.map((slider, index) => {
                const { background = "", headerText = "", headerP = "", aLink = "", aText = "" } = slider
                return (
                    <div key={index} className="ls-slide" data-ls="duration:4000; transition2d:7; kenburnszoom:out; kenburnsscale:1.2;">
                    {/* <!-- bg image  --> */}
                    <img src={background} className="ls-bg" alt="" />
                    {/* <!-- text  --> */}
                    <div className="ls-l header-wrapper" data-ls="offsetyin:150; durationin:700; delayin:200; easingin:easeOutQuint; rotatexin:20; scalexin:1.4; offsetyout:600; durationout:400; parallaxlevel:0;">
                        <div className="header-text">
                            <h1>{headerText}</h1>
                            <p className="header-p">{headerP}</p>
                            {/* <!-- Button --> */}
                            <a  href={aLink}>
                                <div className="blob-btn btn-lg" >
                                {aText}
                                <span className="blob-btn__inner">
                                <span className="blob-btn__blobs">
                                <span className="blob-btn__blob"></span>
                                <span className="blob-btn__blob"></span>
                                <span className="blob-btn__blob"></span>
                                <span className="blob-btn__blob"></span>
                                </span>
                                </span>
                                </div>
                            </a>
                        </div>
                        {/* <!-- header-text  --> */}
                    </div>
                    {/* <!-- ls-l  --> */}
                    </div>
                )
            }) 
            :
            null
            }
            {/* <!-- /slider --> */}
            </div>
        </div>
    )
}