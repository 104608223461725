import React, { Fragment } from "react";

// UI components Import
import Slider from "../components/homepage/slider"
import Services from "../components/homepage/services"
import Newsletter from "../components/homepage/newsletter"
import About from "../components/homepage/about"
import Reviews from "../components/homepage/reviews"
import Menus from "../components/homepage/menus"
import Blobs from "../components/homepage/blogs"
import Catering from "../components/homepage/catering";
import Counter from "../components/homepage/counter";
import Gallery from "../components/homepage/gallery";
import Team from "../components/homepage/team";
import VisitUs from "../components/homepage/visit-us";
import Contact from "../components/homepage/contact";

import Navigation from "../components/common-components/navigation"
import Footer from "../components/common-components/footer"
import Loader from "../components/common-components/loader"

import {sliderContent, servicesContent} from "../constants/content_constants"
function Index(props){
    return (
        <Fragment>
            <Navigation/>
            <Loader/>
            <Slider sliderData={sliderContent}/>
            {/* <!-- curve up svg --> */}
            <svg id="curveUp" className="hidden-xs hidden-sm" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                <path d="M0 100 C 20 0 50 0 100 100 Z" />
            </svg>
            {/* <!-- /curve up svg -->*/}
            {/* Content Section Starts */}
            <Services servicesData={servicesContent}/>
            <Newsletter/>
            <About/>
            <Reviews/>
            <Menus/>
            {/* <Blobs/> */}
            {/* <Catering/> */}
            <Counter/>
            <Gallery/>
            <Team/>
            <VisitUs/>
            <Contact/>
            {/* Content section Ends */}
        <svg id="curveUpColor" className="hidden-xs" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M0 100 C 20 0 50 0 100 100 Z" />
        </svg>
        <Footer/>
        </Fragment>
    )
}

export default Index