import React from 'react'

export default function Services(props){
    const {sectionTitle = "Our Services", header="Baking is our Passion", contentP=[], coverImage, services = []} = props.servicesData
    return(
        <section id="services" className="no-padding-top container-fluid" data-0="background-position:90% 100px;" data-end="background-position:90% -300px;" >
        <div className="container" >
            <div className="col-lg-12">
            <div className="section-heading">
                <h2>{sectionTitle}</h2>
            </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                <img className="img-responsive img-rounded" src={coverImage} data-aos="fade-down"  data-aos-duration="1500"  alt=""/>			   
            </div>
            <div className="col-md-8 res-margin">
                <h3>{header}</h3>
                {Array.isArray(contentP)? 
                    contentP.map((content, index)=>{
                        return <p key={index}>
                            {content}
                        </p>
                    })
                    :null
                }
                <div className="page-scroll">
                    <a href="#contact">
                        <div className="blob-btn" >
                        Contact Us
                        <span className="blob-btn__inner">
                        <span className="blob-btn__blobs">
                        <span className="blob-btn__blob"></span>
                        <span className="blob-btn__blob"></span>
                        <span className="blob-btn__blob"></span>
                        <span className="blob-btn__blob"></span>
                        </span>
                        </span>
                        </div>
                    </a>
            </div>
            </div>
            </div>
            <div id="owl-icons" className="owl-carousel features-icon margin1 light-bg2">
            {Array.isArray(services) && 
                services.map((service, index) =>{
                    const {icon, name, content} = service;
                    return(
                    <div key={index} className="media text-center">
                        <i className={icon}></i>
                        <div className="media-body">
                            <h5>{name}</h5>
                            <p>{content}</p>
                        </div>
                    </div>
                    )
                })
            }
           
            </div>
        </div>
    </section>
    )
}