import React from 'react'

export default function Gallery(){
    return(
        <section id="gallery" className="light-bg2 cake-ornament container-fluid">
        {/* <!-- Section heading --> */}
        <div className="section-heading">
            <h2>Our Gallery</h2>
        </div>
        <div className="container">
            {/* <!-- Polaroids --> */}
            <ul className="polaroids col-md-12">
            {/* <!-- image1 --> */}
            <li className="polaroid-item col-lg-3 col-md-6 col-sm-6">
                <a href="./assets/img/polaroid1.jpg" >
                    <img alt="" src="./assets/img/polaroid1.jpg" className="img-responsive"/>
                    <p>Delicious Cupcakes</p>
                </a>
            </li>
            {/* <!-- image2 --> */}
            <li className="polaroid-item col-lg-3 col-md-6 col-sm-6">
                <a href="./assets/img/polaroid2.jpg" >
                    <img alt="" src="./assets/img/polaroid2.jpg" className="img-responsive"/>
                    <p>Our famous Petit four</p>
                </a>
            </li>
            {/* <!-- image3 --> */}
            <li className="polaroid-item col-lg-3 col-md-6 col-sm-6">
                <a href="./assets/img/polaroid3.jpg" >
                    <img alt="" src="./assets/img/polaroid3.jpg" className="img-responsive"/>
                    <p>Birthday Cupcakes</p>
                </a>
            </li>
            {/* <!-- image4 --> */}
            <li className="polaroid-item col-lg-3 col-md-6 col-sm-6">
                <a href="./assets/img/polaroid4.jpg" >
                    <img alt="" src="./assets/img/polaroid4.jpg" className="img-responsive"/>
                    <p>Classic Xmas Cake</p>
                </a>
            </li>
            {/* <!-- image5 --> */}
            <li className="polaroid-item col-lg-3 col-md-6 col-sm-6">
                <a href="./assets/img/polaroid5.jpg" >
                    <img alt="" src="./assets/img/polaroid5.jpg" className="img-responsive"/>
                    <p>Wedding Cake</p>
                </a>
            </li>
            {/* <!-- image6 --> */}
            <li className="polaroid-item col-lg-3 col-md-6 col-sm-6">
                <a href="./assets/img/polaroid6.jpg" >
                    <img alt="" src="./assets/img/polaroid6.jpg" className="img-responsive"/>
                    <p>Our popular Fruit cake</p>
                </a>
            </li>
            {/* <!-- image7 --> */}
            <li className="polaroid-item  col-lg-3 col-md-6 col-sm-6">
                <a href="./assets/img/polaroid7.jpg" >
                    <img alt="" src="./assets/img/polaroid7.jpg" className="img-responsive"/>
                    <p>Finishing a cake for a Birthday</p>
                </a>
            </li>
            {/* <!-- image8 --> */}
            <li className="polaroid-item col-lg-3 col-md-6 col-sm-6">
                <a href="./assets/img/polaroid8.jpg" >
                    <img alt="" src="./assets/img/polaroid8.jpg" className="img-responsive"/>
                    <p>Cake pops for a birthday</p>
                </a>
            </li>
            {/* <!-- image9 --> */}
            <li className="polaroid-item col-lg-3 col-md-6 col-sm-6">
                <a href="./assets/img/polaroid9.jpg" >
                    <img alt="" src="./assets/img/polaroid9.jpg" className="img-responsive"/>
                    <p>Classic NY Cheesecake</p>
                </a>
            </li>
            {/* <!-- image10 --> */}
            <li className="polaroid-item col-lg-3 col-md-6 col-sm-6">
                <a href="./assets/img/polaroid10.jpg" >
                    <img alt="" src="./assets/img/polaroid10.jpg" className="img-responsive"/>
                    <p>Birthday Cake</p>
                </a>
            </li>
            {/* <!-- image11 --> */}
            <li className="polaroid-item col-lg-3 col-md-6 col-sm-6">
                <a href="./assets/img/polaroid11.jpg" >
                    <img alt="" src="./assets/img/polaroid11.jpg" className="img-responsive"/>
                    <p>Brownie and ice cream</p>
                </a>
            </li>
            {/* <!-- image12 --> */}
            <li className="polaroid-item col-lg-3 col-md-6 col-sm-6">
                <a href="./assets/img/polaroid12.jpg" >
                    <img alt="" src="./assets/img/polaroid12.jpg" className="img-responsive"/>
                    <p>Mini cream tarts with cherry</p>
                </a>
            </li>
            </ul>
            {/* <!-- /ul-polaroids -->  */}
        </div>
        {/* <!-- /container --> */}
    </section>
    )
}