import React from 'react'

export default function Reviews(){
    return (
        <section id="reviews" className="cake-ornament light-bg1 container-fluid">
        <div className="container">
            <h3 className="text-center header-h3">Reviews</h3>
            <div className="col-md-12 margin1">
            {/* <!-- Carousel --> */}
            <div id="owl-testimonial" className=" owl-carousel">
                {/* <!-- Testimonial 1 --> */}
                <div className="testimonial">
                    <div className="testimonial-pic">
                        <img src="./assets/img/testimonial1.jpg" alt="" className="img-circle"/>
                    </div>
                    <div className="testimonial-content">
                        <p className="description">
                        She is very nice and explained well, even after the class I practice in home she gave suggestions. The taste was very nice and the cake looks good. Before this class also I will bake cakes many times but every time i feel it's not the expectations but after this class my expectations fulfilled for fresh cream cake. My family members likes the taste of the cake. Thank you so much, and awaiting to attend other workshops from you.
                        </p>
                        <h3 className="testimonial-title">Manopriya Marimuthu</h3>
                        <small className="post">- Customer</small>
                    </div>
                </div>
                {/* <!-- Testimonial 2 --> */}
                <div className="testimonial">
                    <div className="testimonial-pic">
                        <img src="./assets/img/testimonial2.jpg" alt="" className="img-circle"/>
                    </div>
                    <div className="testimonial-content">
                        <p className="description">
                        It is a very good and  friendly place to learn about baking. She clarified all our doubts and guide us very nicely. I look forward to learn more things related to baking from here. Thank you The Cakes Lounge😊 
                        </p>
                        <h3 className="testimonial-title">Nandhini</h3>
                        <small className="post">- Student</small>
                    </div>
                </div>
                {/* <!-- Testimonial 3 --> */}
                <div className="testimonial">
                    <div className="testimonial-pic">
                        <img src="./assets/img/testimonial3.jpg" alt="" className="img-circle"/>
                    </div>
                    <div className="testimonial-content">
                        <p className="description">
                        Yesterday I attended the offline baking class( that includes fresh cream cakes ,butter cakes ,cookies, brownie) in cakes lounge ..
I knew only the below basic things in baking,but mam really explained me each and everything in detail. so, i understood easily and became more confident in baking...
Whenever I asked doubts ,mam replied very kindly and that's helps me to clear the doubts ..it was a wonderful session yesterday..i am excited to learn more things in baking with mam in further upcoming classes...
Thank you for your valuable time mam☺️
                        </p>
                        <h3 className="testimonial-title">Rubaa Sree Kumar</h3>
                        <small className="post">- Student</small>
                    </div>
                </div>
                {/* <!-- /testimonial --> */}
            </div>
            {/* <!--/owl-testimonials --> */}
            </div>
            {/* <!-- /col-md-12 --> */}
        </div>
        {/* <!-- /container--> */}
    </section>
    )
}