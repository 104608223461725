export const sliderContent = [
    {
        background:"./assets/img/slide1.jpg",
        headerText: "Welcome to The Cakes Lounge",
        headerP:"We have over 300 delicious flavours for you to choose, come and visit our shop today!",
        aLink:"#contact",
        aText:"Contact Us"
    },
    {
        background:"./assets/img/slide2.jpg",
        headerText: "Enjoy our Cakes!",
        headerP:"We have over 300 delicious flavours for you to choose, come and visit our shop today!",
        aLink:"#contact",
        aText:"Contact Us"
    },
    {
        background:"./assets/img/slide3.jpg",
        headerText: "We Bake!",
        headerP:"what you love!",
        aLink:"#contact",
        aText:"Contact Us"
    },
    {
        background:"./assets/img/slide4.jpg",
        headerText: "We Teach!",
        headerP:"to bake for your loved ones!",
        aLink:"#contact",
        aText:"Contact Us"
    }
]

export const servicesContent = {
    sectionTitle:"Our Services",
    header:"Baking is our Passion",
    contentP:[
        "Our passion for baking will always  be reflected in  dedication to customer satisfaction. We go above and beyond to ensure that each client receives not only a delicious and beautifully crafted cake but also a seamless and enjoyable experience. The personal touch, attention to detail, and genuine care that The Cakes Lounge puts into their baking create a everlasting impression and forge long-lasting relationships with their customers."
    ],
    coverImage:"./assets/img/image_KbT.png",
    services:[
        {
            icon:"flaticon-birthday",
            name:"Birthday Parties",
            content:"We are dedicated in creating birthday cakes that are not only visually stunning but also capture the joy and spirit of the occasion."
        },
        {
            icon:"flaticon-food-5",
            name:"Weddings",
            content:"We ensure your wedding cake reflects your unique love story and the atmosphere you want to create."
        },
        {
            icon:"flaticon-electric-mixer",
            name:"Workshops",
            content:"We offers baking classes and workshops to provide individuals with the opportunity to learn and enhance their baking skills."
        },
        {
            icon:"flaticon-wheat",
            name:"Variety of Choices",
            content:"We likely offers a diverse range of pastries to cater to different preferences. This may include cakes, cookies, muffins, tarts, pies, and other delightful treats."
        },
        {
            icon:"flaticon-food-12",
            name:"100% Homemade",
            content:"We emphasize artisan techniques and recipes, showcasing the passion and craftsmanship of the bakers, creating  a sense of authenticity and nostalgia."
        }
    ]
}