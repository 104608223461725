import React from "react";

export default function Menus(){
    return(
        <section id="menu" className="light-bg1 container-fluid">
        <div className="container">
            {/* <!-- Section heading --> */}
            <div className="section-heading">
            <h2>Our Menu</h2>
            </div>
            <div className="col-md-12">
            {/* <!-- nav pills --> */}
            <ul className="nav nav-pills pills-custom nav-stacked col-lg-3" id="menu-nav">
                <li className="active"><a href="#tab_a" data-toggle="pill"><i className="icon flaticon-food-4"></i>Cakes & Pies</a></li>
                <li><a href="#tab_b" data-toggle="pill"><i className="icon flaticon-cupcake-2"></i>Cupcakes</a></li>
                <li><a href="#tab_c" data-toggle="pill"><i className="icon flaticon-cookies"></i>Cookies & Bars</a></li>
                <li><a href="#tab_d" data-toggle="pill"><i className="icon flaticon-croissant"></i>Pastries</a></li>
            </ul>
            {/* <!-- Tab content --> */}
            <div className="tab-content col-lg-9">
                <div className="tab-pane fade in active" id="tab_a">
                    <div className="row">
                        {/* <!-- Menu: Cakes & Pies--> */}
                        <h3 className="text-center">Cakes & Pies</h3>
                        {/* <!-- column --> */}
                        <div className="col-md-6">
                        <div className="menu-body">
                            <div className="menu-section">
                                {/* <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Chocolate Cake
                                    </div>
                                    <div className="menu-item-price">
                                    &#x20B9; 5.5
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Homemade Apple Pie
                                    </div>
                                    <div className="menu-item-price">
                                    &#x20B9; 7
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Special Cake
                                    </div>
                                    <div className="menu-item-price">
                                    &#x20B9; 12
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->  
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    CheeseCake
                                    </div>
                                    <div className="menu-item-price">
                                    &#x20B9; 5.5
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Vanilla Cake
                                    </div>
                                    <div className="menu-item-price">
                                    &#x20B9; 11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Forest Cake
                                    </div>
                                    <div className="menu-item-price">
                                    &#x20B9; 11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->  									 */}
                            </div>
                            {/* <!--/ menu section --> */}
                        </div>
                        {/* <!-- / menu body --> */}
                        </div>
                        {/* <!-- /col-md-6 -->	
                        <!-- column --> */}
                        <div className="col-md-6">
                        <div className="menu-body">
                            <div className="menu-section">
                                {/* <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    CheeseCake
                                    </div>
                                    <div className="menu-item-price">
                                    &#x20B9; 5.5
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Vanilla Cake
                                    </div>
                                    <div className="menu-item-price">
                                    &#x20B9; 11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Forest Cake
                                    </div>
                                    <div className="menu-item-price">
                                    &#x20B9; 11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Boston Cream Pie
                                    </div>
                                    <div className="menu-item-price">
                                    &#x20B9; 7
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->                             
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Brownie
                                    </div>
                                    <div className="menu-item-price">
                                    &#x20B9; 4
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Butter Cake
                                    </div>
                                    <div className="menu-item-price">
                                    &#x20B9; 11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->						                                                         */}
                            </div>
                            {/* <!--/ menu section --> */}
                        </div>
                        {/* <!-- / menu body --> */}
                        </div>
                        {/* <!-- /col-md-6 -->				   */}
                    </div>
                    {/* <!-- /.row --> */}
                </div>
                {/* <!-- /tab-pane  --> */}
                <div className="tab-pane fade" id="tab_b">
                    <div className="row">
                        {/* <!-- Menu: Cupcakes--> */}
                        <h3 className="text-center">Cupcakes</h3>
                        {/* <!-- column --> */}
                        <div className="col-md-6">
                        <div className="menu-body">
                            <div className="menu-section">
                                {/* <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Small Chocolate cupake
                                    </div>
                                    <div className="menu-item-price">
                                    $5.5
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Vanilla Cupcake
                                    </div>
                                    <div className="menu-item-price">
                                    $7
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Caramel Cupcake
                                    </div>
                                    <div className="menu-item-price">
                                    $12
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->  
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    CheeseCake Cupcake
                                    </div>
                                    <div className="menu-item-price">
                                    $5.5
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Vanilla Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Forest Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->  									 */}
                            </div>
                            {/* <!--/ menu section --> */}
                        </div>
                        {/* <!-- / menu body --> */}
                        </div>
                        {/* <!-- /col-md-6 -->	
                        <!-- column --> */}
                        <div className="col-md-6">
                        <div className="menu-body">
                            <div className="menu-section">
                                {/* <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    CheeseCake cupcake
                                    </div>
                                    <div className="menu-item-price">
                                    $5.5
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    set with 6 Cupcakes
                                    </div>
                                    <div className="menu-item-price">
                                    $41
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Forest Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Boston Cream Pie
                                    </div>
                                    <div className="menu-item-price">
                                    $7
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->                             
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Brownie
                                    </div>
                                    <div className="menu-item-price">
                                    $4
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Butter Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->						                                                         */}
                            </div>
                            {/* <!--/ menu section --> */}
                        </div>
                        {/* <!-- / menu body --> */}
                        </div>
                        {/* <!-- /col-md-6 -->				   */}
                    </div>
                    {/* <!-- /.row --> */}
                </div>
                {/* <!-- /tab-pane  --> */}
                <div className="tab-pane fade" id="tab_c">
                    <div className="row">
                        {/* <!-- Menu: Cookies and Bars --> */}
                        <h3 className="text-center">Cookies & Bars</h3>
                        {/* <!-- column --> */}
                        <div className="col-md-6">
                        <div className="menu-body">
                            <div className="menu-section">
                                {/* <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Small Chocolate cupake
                                    </div>
                                    <div className="menu-item-price">
                                    $5.5
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Vanilla Cupcake
                                    </div>
                                    <div className="menu-item-price">
                                    $7
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Special Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $12
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->  
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    CheeseCake
                                    </div>
                                    <div className="menu-item-price">
                                    $5.5
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Vanilla Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Forest Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->  									 */}
                            </div>
                            {/* <!--/ menu section --> */}
                        </div>
                        {/* <!-- / menu body --> */}
                        </div>
                        {/* <!-- /col-md-6 -->	
                        <!-- column --> */}
                        <div className="col-md-6">
                        <div className="menu-body">
                            <div className="menu-section">
                                {/* <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    CheeseCake
                                    </div>
                                    <div className="menu-item-price">
                                    $5.5
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Vanilla Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Forest Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Boston Cream Pie
                                    </div>
                                    <div className="menu-item-price">
                                    $7
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->                             
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Brownie
                                    </div>
                                    <div className="menu-item-price">
                                    $4
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Butter Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->						                                                         */}
                            </div>
                            {/* <!--/ menu section --> */}
                        </div>
                        {/* <!-- / menu body --> */}
                        </div>
                        {/* <!-- /col-md-6 -->				   */}
                    </div>
                    {/* <!-- /.row --> */}
                </div>
                {/* <!-- /tab-pane  --> */}
                <div className="tab-pane fade" id="tab_d">
                    <div className="row">
                        {/* <!-- Menu: Pastries --> */}
                        <h3 className="text-center">Pastries</h3>
                        {/* <!-- column --> */}
                        <div className="col-md-6">
                        <div className="menu-body">
                            <div className="menu-section">
                                {/* <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Small Chocolate cupake
                                    </div>
                                    <div className="menu-item-price">
                                    $5.5
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Vanilla Cupcake
                                    </div>
                                    <div className="menu-item-price">
                                    $7
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Special Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $12
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->  
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    CheeseCake
                                    </div>
                                    <div className="menu-item-price">
                                    $5.5
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Vanilla Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Forest Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->  									 */}
                            </div>
                            {/* <!--/ menu section --> */}
                        </div>
                        {/* <!-- / menu body --> */}
                        </div>
                        {/* <!-- /col-md-6 -->	
                        <!-- column --> */}
                        <div className="col-md-6">
                        <div className="menu-body">
                            <div className="menu-section">
                                {/* <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    CheeseCake
                                    </div>
                                    <div className="menu-item-price">
                                    $5.5
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Vanilla Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Forest Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Boston Cream Pie
                                    </div>
                                    <div className="menu-item-price">
                                    $7
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->                             
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Brownie
                                    </div>
                                    <div className="menu-item-price">
                                    $4
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->
                                <!-- Item starts --> */}
                                <div className="menu-item">
                                    <div className="menu-item-name">
                                    Butter Cake
                                    </div>
                                    <div className="menu-item-price">
                                    $11
                                    </div>
                                    <div className="menu-item-description">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy.
                                    </div>
                                </div>
                                {/* <!-- Item ends -->						                                                         */}
                            </div>
                            {/* <!--/ menu section --> */}
                        </div>
                        {/* <!-- / menu body --> */}
                        </div>
                        {/* <!-- /col-md-6 -->				   */}
                    </div>
                    {/* <!-- /.row --> */}
                </div>
                {/* <!-- /tab-pane  --> */}
            </div>
            {/* <!-- tab content --> */}
            </div>
            {/* <!-- /col-md-12 --> */}
        </div>
        {/* <!-- /container -->		  */}
    </section>
    )
}