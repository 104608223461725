import React from "react";

export default function  Navigation(){
    return(
        <nav className="navbar navbar-custom navbar-fixed-top">
        {/* <!-- Brand and toggle get grouped for better mobile display --> */}
        <div className="navbar-header">
            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-brand-centered">
            <i className="fa fa-bars"></i>
            </button>
            <div className="navbar-brand-centered page-scroll">
            <a href="#page-top"><img src="./assets/img/logo.png" className="img-responsive"  alt=""/></a>
            </div>
        </div>
        {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
        <div className="collapse navbar-collapse" id="navbar-brand-centered">
            <div className="container">
            <ul className="nav navbar-nav page-scroll navbar-left">
                <li><a href="#page-top">Home</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#reviews">Reviews</a></li>
                
            </ul>
            <ul className="nav navbar-nav page-scroll navbar-right">
                <li><a href="#menu">Menu</a></li>
                {/* <li><a href="#catering">Catering</a></li> */}
                <li><a href="#gallery">Gallery</a></li>
                <li><a href="#team">Team</a></li>
                <li><a href="#contact">Contact</a></li>
                {/* <!-- Dropdown --> */}
                {/* <li className="dropdown active">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">Pages<b className="caret"></b></a>
                    <ul className="dropdown-menu">
                        <li><a href="blog-home.html">Blog Home</a></li>
                        <li><a href="blog-single.html">Blog Single</a></li>
                        <li className="divider"></li>
                        <li><a href="elements.html">Elements</a></li>
                    </ul>
                </li> */}
            </ul>
            </div>
        </div>
        {/* <!-- /.navbar-collapse --> */}
    </nav>
    )
}