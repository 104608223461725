import React from 'react'

export default function About(){
    return(
        <section id="about" className="cake-ornament container-fluid">
        <div className="container">
            {/* <!-- Section Heading --> */}
            <div className="section-heading">
            <h2>About Us</h2>
            </div>
            {/* <!-- Text --> */}
            <div className="row">
            <div className="col-lg-7">
                <h3>Delicious cakes since 2016</h3>
                <p>The Cakes Lounge takes pride in their role as a trusted home baker within their community. They cherish the opportunity to be a part of their clients' special moments, be it birthdays, weddings, or any other celebration. With their homemade delights , The Cakes Lounge aims to evoke emotions, create lasting memories, and bring smiles of delight to all who indulge in their delectable creations.
                </p>
                <ul className="custom">
                    <li>We are a family owned Business</li>
                    <li>Egg based and Egg free options always available</li>
                    <li>We use high quality fair trade Chocolate in our products</li>
                    <li>You can order your own custom cake with us</li>
                </ul>
            </div>
            {/* <!-- /col-lg-7 --> */}
            <div className="col-lg-5 res-margin">
                <div id="owl-about" className="owl-carousel">
                    <div className="item">
                        <img className="img-responsive img-rounded" src="./assets/img/about1.jpg" alt=""/>
                    </div>
                    <div className="item">
                        <img className="img-responsive  img-rounded" src="./assets/img/about2.jpg" alt=""/>
                    </div>
                    <div className="item">
                        <img className="img-responsive  img-rounded" src="./assets/img/about3.jpg" alt=""/>
                    </div>
                </div>
                {/* <!-- /owl-about --> */}
            </div>
            {/* <!-- /col-lg-5 --> */}
            </div>
            {/* <!-- /row --> */}
            {/* <div className="row">
            <div className="col-md-7">
                <h4>Our Location</h4>
                <p>Placerat a ultricies a, posuere a nibh. Fusce mollis imperdiet interdum donec eget metus auguen unc vel mauris ultricies, vest ibulum orci eget,Fusce mollis imperdiet interdum donec eget metus auguen unc vel lorem ispuet Ibu lum orci eget, viverra elit liquam erat volut pat phas ellus ac sodales Lorem ipsum dolor sit amet, consectetur adipisicing elit uasi quidem minus id omnis.
                </p>
            </div>
            <div className="col-md-5">
                <div className="alert alert-info col-md-12" data-aos="fade-up"  data-aos-duration="1500" >
                    <h5>Order a custom cake</h5>
                    <p className="text-light">
                        Fusce mollis imperdiet interdum donec eget metus auguen unc vel lorem ispuet Ibu lum orci eget, viverra elit liquam erat volut pat phas ellus ac sodales Lorem ipsum dolor sit amet consectetur.
                    </p>
                </div>
                
            </div>
            
            </div> */}
            {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
    </section>
    )
}