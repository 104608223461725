import React from 'react'

export default function Team(){
    return(
        <section id="team">
        <div className="container">
            {/* <!-- Section Heading --> */}
            <div className="section-heading">
            <h2>Our Team</h2>
            </div>
            <div className="col-lg-7">
            <h3>We make the magic happen</h3>
            <p>lacerat a ultricies a, posuere a nibh. Fusce mollis imperdiet interdum donec eget metus auguen unc vel mauris ultricies, vest ibulum orci eget,Fusce mollis imperdiet interdum donec eget metus auguen unc vel lorem ispuet Ibu lum orci eget, viverra elit liquam erat volut pat phas ellus ac s Li Europan lingues es membres del sam familie. Lor separat existentie es un myth. Por scientie, musica, sport etc, litot Europa usa li sam vocabular. Li lingues differe solmen in li grammatica, li pronunciation e li plu commun vocabules. Omnicos directe al desirabilite de un nov lingua franca: On refusa continuar payar custosi traductores. </p>
            </div>
            {/* <!-- Parallax object --> */}
            <div className="parallax-object hidden-sm hidden-xs hidden-md " data-bottom-top="right:15%;margin-top:-100px;transform:rotate(-10deg);" data-top="transform:rotate(0deg);" >
            {/* <!-- Image --> */}
            <img src="./assets/img/bgcake1.png" alt=""/>
            </div>
            <div id="owl-team" className="margin1 owl-carousel">
            <div className="col-md-12">
                {/* <!-- team member 1 --> */}
                <div className="our-team cake-ornament">
                    {/* <!-- picture --> */}
                    <div className="pic">
                        <img src="./assets/img/lavanya_baker.jpg" alt=""/>
                    </div>
                    {/* <!-- member info --> */}
                    <div className="team-content">
                        <h3 className="title">Lavanya T</h3>
                        <span className="post">Master Baker</span>
                    </div>
                    {/* <!-- Social icons --> */}
                    <ul className="social">
                        <li><a href="#" className="fa fa-facebook"></a></li>
                        <li><a href="#" className="fa fa-twitter"></a></li>
                        <li><a href="#" className="fa fa-google-plus"></a></li>
                        <li><a href="#" className="fa fa-linkedin"></a></li>
                    </ul>
                    {/* <!--/ul  --> */}
                </div>
                {/* <!-- /our-team --> */}
            </div>
            {/* <!-- /col-md-12 --> */}
            <div className="col-md-12">
                {/* <!-- team member 2 --> */}
                <div className="our-team cake-ornament">
                    {/* <!-- picture --> */}
                    <div className="pic">
                        <img src="./assets/img/team2.jpg" alt=""/>
                    </div>
                    {/* <!-- member info --> */}
                    <div className="team-content">
                        <h3 className="title">Vijaya Lakshmi</h3>
                        <span className="post">Co-Owner</span>
                    </div>
                    {/* <!-- Social icons --> */}
                    <ul className="social">
                        <li><a href="#" className="fa fa-facebook"></a></li>
                        <li><a href="#" className="fa fa-twitter"></a></li>
                        <li><a href="#" className="fa fa-google-plus"></a></li>
                        <li><a href="#" className="fa fa-linkedin"></a></li>
                    </ul>
                    {/* <!--/ul  --> */}
                </div>
                {/* <!-- /our-team --> */}
            </div>
            {/* <!-- /col-md-12 --> */}
            <div className="col-md-12">
                {/* <!-- team member 3 --> */}
                <div className="our-team cake-ornament">
                    {/* <!-- picture --> */}
                    <div className="pic">
                        <img src="./assets/img/team3.jpg" alt=""/>
                    </div>
                    {/* <!-- member info --> */}
                    <div className="team-content">
                        <h3 className="title">Thiyagarajan</h3>
                        <span className="post">Co-Owner</span>
                    </div>
                    {/* <!-- Social icons --> */}
                    <ul className="social">
                        <li><a href="#" className="fa fa-facebook"></a></li>
                        <li><a href="#" className="fa fa-twitter"></a></li>
                        <li><a href="#" className="fa fa-google-plus"></a></li>
                        <li><a href="#" className="fa fa-linkedin"></a></li>
                    </ul>
                    {/* <!--/ul  --> */}
                </div>
                {/* <!-- /our-team --> */}
            </div>
            {/* <!-- /col-md-12 --> */}
            <div className="col-md-12">
                {/* <!-- team member 4 --> */}
                <div className="our-team cake-ornament">
                    {/* <!-- picture --> */}
                    <div className="pic">
                        <img src="./assets/img/team4.jpg" alt=""/>
                    </div>
                    {/* <!-- member info --> */}
                    <div className="team-content">
                        <h3 className="title">Kowtham</h3>
                        <span className="post">Baker</span>
                    </div>
                    {/* <!-- Social icons --> */}
                    <ul className="social">
                        <li><a href="#" className="fa fa-facebook"></a></li>
                        <li><a href="#" className="fa fa-twitter"></a></li>
                        <li><a href="#" className="fa fa-google-plus"></a></li>
                        <li><a href="#" className="fa fa-linkedin"></a></li>
                    </ul>
                    {/* <!--/ul  --> */}
                </div>
                {/* <!-- /our-team --> */}
            </div>
            {/* <!-- /col-md-12 --> */}
            <div className="col-md-12">
                {/* <!-- team member 5 --> */}
                <div className="our-team cake-ornament">
                    {/* <!-- picture --> */}
                    <div className="pic">
                        <img src="./assets/img/team5.jpg" alt=""/>
                    </div>
                    {/* <!-- member info --> */}
                    <div className="team-content">
                        <h3 className="title">Kavya Sandeep</h3>
                        <span className="post">Baker</span>
                    </div>
                    {/* <!-- Social icons --> */}
                    <ul className="social">
                        <li><a href="#" className="fa fa-facebook"></a></li>
                        <li><a href="#" className="fa fa-twitter"></a></li>
                        <li><a href="#" className="fa fa-google-plus"></a></li>
                        <li><a href="#" className="fa fa-linkedin"></a></li>
                    </ul>
                    {/* <!--/ul  --> */}
                </div>
                {/* <!-- /our-team --> */}
            </div>
            {/* <!-- /col-md-12 --> */}
            </div>
            {/* <!-- /owl-team --> */}
        </div>
        {/* <!-- /container --> */}
    </section>
    )
}