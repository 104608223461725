import React from 'react'

export default function Newsletter(){
    return(
        <section id="newsletter" className="container-fluid bg-primary">
        <div className="container">
            <div className="row">
            {/* <div className="col-lg-5"> */}
                {/* <!-- image  --> */}
                {/* <img src="./assets/img/slide3.jpg" className="img-responsive newsletter-img" alt="" /> */}
            {/* </div> */}
            <div className="col-lg-5 align-center">
                <h3 className="res-margin text-light">Join our Newsletter</h3>
                <p className="text-light">We will send you news once a week</p>
                {/* <!-- Form -->				 */}
                <div id="mc_embed_signup">
                    <form method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                        <div id="mc_embed_signup_scroll">
                        <div className="mc-field-group">
                            <div className="input-group">
                                <input className="form-control input-lg required email" type="email" value="" name="EMAIL" placeholder="Your email here" id="mce-EMAIL"/>
                                <span className="input-group-btn">
                                <button className="blob-btn btn btn-lg" type="submit" value="Subscribe" name="subscribe"  id="mc-embedded-subscribe">
                                Subscribe
                                <span className="blob-btn__inner">
                                <span className="blob-btn__blobs">
                                <span className="blob-btn__blob"></span>
                                <span className="blob-btn__blob"></span>
                                <span className="blob-btn__blob"></span>
                                <span className="blob-btn__blob"></span>
                                </span>
                                </span>
                                </button>
                                </span>
                            </div>
                            {/* <!-- Subscription results --> */}
                            <div id="mce-responses" className="mailchimp">
                                <div className="alert alert-danger response" id="mce-error-response"></div>
                                <div className="alert alert-success response" id="mce-success-response"></div>
                            </div>
                        </div>
                        {/* <!-- /mc-fiel-group -->									 */}
                        </div>
                        {/* <!-- /mc_embed_signup_scroll --> */}
                    </form>
                    {/* <!-- /form ends --> */}
                </div>
                {/* <!-- /mc_embed_signup -->			 */}
            </div>
            {/* <!-- /col-lg --> */}
            </div>
            {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
    </section>
    )
}