import React, {useEffect, useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

// import ReCaptcha from "./recaptcha";

export default function Contact(){
    const captchaRef = useRef(null)
    const [token, setToken] = useState('');
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (token && token.length) {
            setSubmitEnabled(true)
        }
    }, [token])

    const handleToken = (token) => {
        console.log("token", token)
        setToken(token)
    }


    const handleOnSubmit = async (e) => {
        console.log("name", name, "phone", phone, "subject", subject, "message", message)
        
        e.preventDefault();
        try {
        const response = await fetch(`https://api.thecakeslounge.in/api/web/contact`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, phone, subject, message})
        });
        
        captchaRef.current.reset();

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Response:', data);
        
        // You can handle the response data here
        } catch (error) {
        console.error('Error:', error);
        // Handle errors here
        }
    }


    return(
        <section id="contact" className="container-fluid cake-ornament">
        <div className="container">
            <div className="col-lg-12">
            {/* <!-- Section heading --> */}
            <div className="section-heading">
                <h2>Contact Us</h2>
            </div>
            </div>
            {/* <!-- Contact icons --> */}
            <div className="row">
            <div className="col-lg-12 col-md-12">
                <div className="col-md-4">
                    <div className="contact-icon res-margin">
                        {/* <!---icon--> */}
                        <i className="fa fa-envelope top-icon"></i>
                        {/* <!-- contact-icon info--> */}
                        <div className="contact-icon-info">
                        <p className="subtitle">Send us a Message</p>
                        <p>Email address: <br/><a href="mailto:	thecakeslounge@gmail.com">thecakeslounge@gmail.com
</a></p>
                        </div>
                    </div>
                </div>
                {/* <!-- /contact-icon--> */}
                <div className="col-md-4">
                    <div className="contact-icon res-margin">
                        {/* <!---icon--> */}
                        <i className="fa fa-map-marker top-icon"></i>
                        {/* <!-- contact-icon info--> */}
                        <div className="contact-icon-info">
                        <p className="subtitle">Visit our Location</p>
                        <p>L310A, Alangari Illam, <br/>Koottapalli Colony, <br/>Tiruchengode, <br/>Tamil Nadu 637211</p>
                        </div>
                    </div>
                </div>
                {/* <!-- /contact-icon--> */}
                <div className="col-md-4">
                    <div className="contact-icon res-margin">
                        {/* <!---icon--> */}
                        <i className="fa fa-phone top-icon"></i>
                        {/* <!-- contact-icon info--> */}
                        <div className="contact-icon-info">
                        <p className="subtitle">Call us</p>
                        <p>Phone number: <br/>(+91) 63 85 63 2016</p>
                        </div>
                    </div>
                    {/* <!-- /contact-icon--> */}
                </div>
                {/* <!-- /col-md-4--> */}
            </div>
            {/* <!-- /col-lg --> */}
            </div>
            <div className="row margin1">
            <div className="col-md-5">
                <h4>Get in touch with us</h4>
                {/* <p>Consectetur adipiscing elit Etiam porta sem malesuada magna mollis euismod. Nulla vitae elit libero, a pharetra augue. Aenean eu leo quam. Pellentesque ornare sem lacinia.</p> */}
                <div className="form-style" id="contact_form">
                            {/* <!-- Subscription results --> */}
                            {/* <div id="mce-responses" className="mailchimp">
                                <div className="alert alert-danger response" id="mce-error-response"></div>
                                <div className="alert alert-success response" id="mce-success-response"></div>
                            </div> */}
                    {/* <!-- Contact Form -->
                    <!-- Form Starts --> */}
                    <div className="form-group">
                    <label>Name<span className="required">*</span></label>
                    <input type="text" name="name" className="form-control input-field" placeholder="" value={name} onChange={(e) => setName(e.target.value)} required=""/>                    
                    <label>Phone<span className="required">*</span></label>
                    <input type="number" name="phone" className="form-control input-field" placeholder="" length="10" value={phone} onChange={(e) => setPhone(e.target.value)} required=""/> 
                    <label>Subject</label>						
                    <input type="text" name="subject" className="form-control input-field" value={subject} onChange={(e) => setSubject(e.target.value) } placeholder=""/>                                          
                    <label>Message<span className="required">*</span></label>
                    <textarea name="message" id="message" className="textarea-field form-control" rows="4" placeholder="" value={message} onChange={(e) => setMessage(e.target.value) } required=""></textarea>
                    
                    <ReCAPTCHA sitekey="6LdRRLspAAAAANcdte2vu8pYZRoZ2m51DkYmGcU6" onChange={handleToken} ref={captchaRef}/>

                    {/* <!-- Button --> */}
                    <button className="blob-btn" id="submit_btn" onClick={handleOnSubmit} disabled ={!submitEnabled}>
                        Send Message
                        {submitEnabled &&
                        <span className="blob-btn__inner">    
                            <span className="blob-btn__blobs">
                                <span className="blob-btn__blob"></span>
                                <span className="blob-btn__blob"></span>
                                <span className="blob-btn__blob"></span>
                                <span className="blob-btn__blob"></span>
                            </span>
                        </span>}
                    </button>
                </div>
                {/* <!-- Contact results --> */}
                <div id="contact_results"></div>
            </div>
            {/* <!--/Contact_form --> */}
        </div>
        {/* <!-- / col-md-5-->
        <!-- Map with Reveal Box --> */}
        <div className="col-md-6 col-md-offset-1 revealedBox goLeft res-margin">
            {/* <!-- Map --> */}
            {/* <!-- <div className="contentBox" id="map-canvas"></div> --> */}
            {/* <!-- Add Google Map embed --> */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d125167.53174892745!2d77.79904054206919!3d11.372251124315602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba961ef75ce7127%3A0xd6a580d51717a217!2sThe%20Cakes%20Lounge!5e0!3m2!1sen!2sin!4v1685726384788!5m2!1sen!2sin" className="contactUsMap" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            <span></span> 
            <span></span> 
            <span></span> 
            <span></span>    
        </div>
        {/* <!--/col-lg-6  --> */}
        </div>
        {/* <!--/row -->    */}
    </div>
    {/* <!-- /container--> */}
    </section>
    )
}