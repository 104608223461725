import React from "react";

export default function Loader(){
    return(
    <div id="loading">
        <div id="loading-center">
            <div id="loading-center-absolute">
            <div className="object-load" id="object_one"></div>
            <div className="object-load" id="object_two"></div>
            <div className="object-load" id="object_three"></div>
            </div>
        </div>
    </div>
    )
}