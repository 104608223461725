import React from 'react'

export default function Footer(){

    return(
        <footer className="bg-primary">
        <div className="container">
            <div className="row text-center">
            {/* <!-- social media and logo --> */}
            <div className="col-lg-4">
                <h6  className="text-light">Opening Times</h6>
                <ul className="ul-custom ul-no-margin text-light">
                    <li>Mon - Sun: 9am-7pm</li>
                    {/* <li>Weekends & Holidays: Closed</li> */}
                </ul>
            </div>
            {/* <!-- /row --> */}
            <div className="col-lg-4">
                <a href="#page-top"><img src="./assets/img/logo.png"  alt="" className="img-responsive center-block"/></a>
            </div>
            <div className="col-lg-4">
                {/* <!-- social-icons -->	 */}
                <h6  className="text-light">Follow us</h6>
                <div className="social-media">
                    <a href="#" title=""><i className="fa fa-twitter"></i></a>
                    <a href="#" title=""><i className="fa fa-facebook"></i></a>
                    <a href="#" title=""><i className="fa fa-linkedin"></i></a>
                    <a href="#" title=""><i className="fa fa-pinterest"></i></a>
                    <a href="#" title=""><i className="fa fa-instagram"></i></a>
                </div>
            </div>
            {/* <!-- /col-lg-4 -->  			 */}
            </div>
            {/* <!-- /row --> */}
            <div className="row">
            <div className="col-md-12 credits text-center">
                <p>Copyright © 2018 - 2023 / Designed by  <a href="https://www.linkedin.com/in/vijaya-kumar/" target="_blank">Vijaya Kumar</a></p>
                {/* <!-- /container -->
                <!-- Go To Top Link --> */}
                <div className="page-scroll hidden-sm hidden-xs">
                    <a href="#page-top" className="back-to-top"><i className="fa fa-angle-up"></i></a>
                </div>
            </div>
            {/* <!-- /col-md-12 --> */}
            </div>
            {/* <!-- /row--> */}
        </div>
        {/* <!-- /container --> */}
    </footer>
    )
}