import React from 'react'

export default function Counter(){
    return(
    <section id="counter-section" className="container-fluid border-shape bg-overlay">
        <div className="container text-light">
            <h3 className="text-center header-h3">Our Stats this Month</h3>
            <div id="counter" className="row">
            {/* <!-- Counter --> */}
            <div className="col-lg-3 col-md-6">
                <div className="counter">
                    <i className="counter-icon flaticon-gingerbread"></i>
                    {/* <!-- insert your final value on data-count= --> */}
                    <div className="counter-value" data-count="250">0</div>
                    <h5 className="title">Happy Customers</h5>
                </div>
                {/* <!-- /counter --> */}
            </div>
            {/* <!-- /col-lg -->
            <!-- Counter --> */}
            <div className="col-lg-3 col-md-6">
                <div className="counter">
                    <i className="counter-icon  flaticon-food-5"></i>
                    {/* <!-- insert your final value on data-count= --> */}
                    <div className="counter-value" data-count="140">0</div>
                    <h5 className="title">Cakes Made</h5>
                </div>
                {/* <!-- /counter --> */}
            </div>
            {/* <!-- /col-lg -->
            <!-- Counter --> */}
            <div className="col-lg-3 col-md-6">
                <div className="counter">
                    <i className="counter-icon flaticon-electric-mixer"></i>
                    {/* <!-- insert your final value on data-count= --> */}
                    <div className="counter-value" data-count="14">0</div>
                    <h5 className="title">Professionals</h5>
                </div>
                {/* <!-- /counter --> */}
            </div>
            {/* <!-- /col-lg -->
            <!-- Counter --> */}
            <div className="col-lg-3 col-md-6">
                <div className="counter">
                    <i className="counter-icon flaticon-food-8"></i>
                    {/* <!-- insert your final value on data-count= --> */}
                    <div className="counter-value" data-count="230">0</div>
                    <h5 className="title">Cupcakes baked</h5>
                </div>
                {/* <!-- /counter --> */}
            </div>
            {/* <!-- /col-lg --> */}
            </div>
            {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
    </section>
    )
}