import React from 'react'

export default function VisitUs(){
    return(
        <section id="call-to-action" className="border-shape container-fluid" data-0="background-position:100% 20px;" data-end="background-position:100% -40px">
        <div className="container">
            <div className="col-md-6 col-sm-6 col-centered well text-center">
            <h4>Visit us Today!</h4>
            <p>Lotam tempore optio doloremque laboriosam quas, quos eaque molestias odio aut eius animi Suspendisse a elementum anteu imperdiet et maecenas eu eros non nibh aliquet iaculis..</p>
            {/* <!-- Button --> */}
            <div className="page-scroll">
                <a href="#contact">
                    <div className="blob-btn no-margin-bottom" >
                        Contact Us
                        <span className="blob-btn__inner">
                        <span className="blob-btn__blobs">
                        <span className="blob-btn__blob"></span>
                        <span className="blob-btn__blob"></span>
                        <span className="blob-btn__blob"></span>
                        <span className="blob-btn__blob"></span>
                        </span>
                        </span>
                    </div>
                </a>
            </div>
            {/* <!-- /page-scroll --> */}
            </div>
            {/* <!-- /col-md-6 --> */}
        </div>
        {/* <!-- /.container --> */}
    </section>
    )
}